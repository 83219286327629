<template>
  <div v-loading="isLoading" class="road_inside_monitor page">
    <div class="page-card">
      <easy-card :title=parkinglotInfo.name padding="none">
        <div class="parking-summary">
          <div class="parking-thumb">
            <p>
              <span class="label">车场类型</span>
              <span class="value">{{ parkinglotInfo.parkingLotType === 1 ? "路内" : "路外" }}停车场</span>
            </p>
            <p>
              <span class="label">运营时长</span>
              <span class="value">{{ parkinglotInfo.operatingTime }}天</span>
            </p>
            <p>
              <span class="label">泊位信息</span>
              <span class="value">临停  {{ parkinglotInfo.temporaryStop }}</span>
            </p>
          </div>
          <div class="parking-amount">
            <div class="amount-item">
              <p>订单应收(元)</p>
              <div>{{ amountInfo.amountSummary }}</div>
            </div>
            <div class="amount-item">
              <p>订单实收(元)</p>
              <div>{{ amountInfo.tradeCount }}</div>
            </div>
            <div class="amount-item">
              <p>服务车次(次)</p>
              <div>{{ amountInfo.serviceYard }}</div>
            </div>
            <div class="amount-item">
              <p>收缴率(%)</p>
              <div>{{ amountInfo.collectionRate }}</div>
            </div>
            <div class="statistics-type">
              <p
                  v-for="item in tab"
                  :key="item.timeType"
                  :class="item.timeType === currentTab ? 'active' : ''"
                  @click="timeSearch(item.timeType)"
              >
                {{ item.text }}
              </p>
              <!-- <p>昨日</p>
              <p>本周</p>
              <p>本月</p> -->
            </div>
          </div>
        </div>
        <div class="parking-monitor">
          <div class="auto-monitor monitor-card">
            <easy-card padding="none" title="车辆实时监控">
              <div class="info-card">
                <span class="label">临停</span>
                <span class="value">{{ vehicleMonitorInfo.temporayStop }}</span>
              </div>
              <div class="monitor-card__content">
                <span class="tip">暂无交易信息</span>
              </div>
            </easy-card>
          </div>
          <div class="device-monitor monitor-card">
            <easy-card padding="none" title="设备异常监控">
              <div class="info-row">
                <div class="info-card">
                  <span class="label">正常</span>
                  <span class="value">{{ deviceMonitorInfo.normal }}</span>
                </div>
                <div class="info-card">
                  <span class="label">异常</span>
                  <span class="value">{{ deviceMonitorInfo.abnormal }}</span>
                </div>
              </div>
              <div class="monitor-card__content">
                <span class="tip">暂无交易信息</span>
              </div>
            </easy-card>
          </div>
          <div class="inspector-monitor monitor-card">
            <easy-card padding="none" title="今日巡检监控">
              <div class="info-card">
                <span class="label">今日签到</span>
                <span class="value">{{ inspectorMonitorInfo.total }}</span>
              </div>
              <div class="monitor-card__content">
                <span class="tip">暂无交易信息</span>
              </div>
            </easy-card>
          </div>
        </div>
      </easy-card>
    </div>
    <div class="page-card" style="margin-top: 10px">
      <easy-card padding="none" title="近24小时泊位占用率">
        <div class="content">
          <BaseChart
              ref="spaceOccupancyRateChart"
              :option="spaceOccupancyRateOption"
              style="width: 100%; height: 100%"
          />
        </div>
      </easy-card>
    </div>
  </div>
</template>

<script>
import BaseChart from "@/components/BaseChart";
import {
  deviceMonitor,
  getAmountRelated,
  getDetailByParkingLotId,
  inspectorMonitor,
  spaceOccupancyRate,
  vehicleMonitor
} from "@/api/monitor";

export default {
  name: "road-inside_monitor",
  components: {
    BaseChart,
  },
  data() {
    return {
      parkinglotId: "",
      isLoading: false,
      parkinglotInfo: {},
      amountInfo: {},
      vehicleMonitorInfo: {},
      deviceMonitorInfo: {},
      inspectorMonitorInfo: {},
      currentTab: "1",
      tab: [
        {
          text: "今日",
          timeType: "1",
        },
        {
          text: "昨日",
          timeType: "2",
        },
        {
          text: "本周",
          timeType: "3",
        },
        {
          text: "本月",
          timeType: "4",
        },
      ],
      timeFrame: [
        "00时",
        "01时",
        "02时",
        "03时",
        "04时",
        "05时",
        "06时",
        "07时",
        "08时",
        "09时",
        "10时",
        "11时",
        "12时",
        "13时",
        "14时",
        "15时",
        "16时",
        "17时",
        "18时",
        "19时",
        "20时",
        "21时",
        "22时",
        "23时",],
      spaceOccupancyRateOption: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            const date = this.timeFrame[params[0].dataIndex];
            const name = params[0].seriesName;
            const value = params[0].value;
            return '<div>' + date + '<br>' + params[0].marker + name + ' : ' + value + '</div>'
          }
        },
        grid: {
          left: "30",
          right: "10",
        },
        xAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
            show: true,
          },
          data: [
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat",
            "Sun",
            "ss",
            "s1",
            "s2",
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat",
            "Sun",
            "ss",
            "s1",
            "s2",
            "1",
            "2",
          ],
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
            show: true,
          },
        },
        series: [
          {
            data: [
              120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70, 110, 130,
              120, 200, 150, 80, 70, 110, 130, 230, 100, 200,
            ],
            name: "占用率",
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: "#0768FD"
            },
          },
        ],
      },
    };
  },
  methods: {
    //获取上个页面的传参
    getRequest() {
      var url = window.location.href;
      var id = url.substr(url.indexOf("=") + 1);
      this.parkinglotId = id;
    },
    //查询车场信息
    getParkinglotInfo() {
      getDetailByParkingLotId(this.parkinglotId).then(res => {
        if (res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.parkinglotInfo = {
            chargingPile: returnObject.chargingPile,
            name: returnObject.name,
            operatingTime: returnObject.operatingTime,
            appointment: returnObject.appointment,
            parkingLotType: returnObject.parkingLotType,
            temporaryStop: returnObject.temporaryStop
          }
        }
      })
    },
    //根据时间查询车场金额收益
    timeSearch(e) {
      this.currentTab = e;
      this.getAmountInfo();
    },
    getAmountInfo() {
      this.isLoading = true;
      getAmountRelated(this.parkinglotId, this.currentTab).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.amountInfo = {
            tradeCount: returnObject.tradeCount,
            collectionRate: returnObject.collectionRate,
            amountSummary: returnObject.amountSummary,
            serviceYard: returnObject.serviceYard
          }
        }
      })
    },
    //车辆实时监控信息查询
    getVehicleMonitorInfo() {
      vehicleMonitor(this.parkinglotId).then(res => {
        if (res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.vehicleMonitorInfo = {
            temporayStop: returnObject.temporayStop,
          }
        }
      })
    },
    //设备异常监控信息查询
    getDeviceMonitor() {
      deviceMonitor(this.parkinglotId).then(res => {
        if (res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.deviceMonitorInfo = {
            normal: returnObject.normal,
            abnormal: returnObject.abnormal
          }
        }
      })
    },
    //巡检监控信息查询
    getInspectorMonitorInfo() {
      inspectorMonitor(this.parkinglotId).then(res => {
        if (res.code === 30 && res.result) {
          this.inspectorMonitorInfo = {
            total: res.returnObject.total
          }
        }
      })
    },
    //近24小时泊位占用率信息查询
    getSpaceOccupancyRate() {
      spaceOccupancyRate(this.parkinglotId).then(res => {
        if (res.code === 30 && res.result) {
          this.spaceOccupancyRateOption.xAxis.data = res.returnObject.map(item => {
            return item.hour
          });
          this.spaceOccupancyRateOption.series[0].data = res.returnObject.map(item => {
            return item.occupancyRate
          });
          this.timeFrame = res.returnObject.map((item) => {
            return item.date
          })
          this.$refs.spaceOccupancyRateChart.render(
              this.spaceOccupancyRateOption
          );
        }
      })
    }
  },
  mounted() {
    this.getRequest();
    this.isLoading = true;
    Promise.all([
      this.getParkinglotInfo(),
      this.getAmountInfo(),
      this.getVehicleMonitorInfo(),
      this.getDeviceMonitor(),
      this.getInspectorMonitorInfo(),
      this.getSpaceOccupancyRate(),
    ]).then(() => {
      this.isLoading = false;
    })
  },
};
</script>

<style lang="less" scoped>
.road_inside_monitor {
  .page-card {
    padding: 10px;
    background: #fff;

    .content {
      height: 270px;
    }
  }

  .parking-summary {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .parking-thumb {
      width: 350px;
      border: 1px solid hsla(0, 0%, 88.2%, 0.8823529411764706);
      margin-right: 10px;
      height: 130px;
      font-size: 16px;

      p {
        padding: 10px 0 0 10px;

        .label {
          color: #565c68;
          margin-right: 10px;
        }
      }
    }

    .parking-amount {
      border: 1px solid hsla(0, 0%, 88.2%, 0.8823529411764706);
      flex: 1;
      display: flex;
      flex-direction: row;

      .amount-item {
        flex: 1;

        p {
          font-size: 16px;
          color: #0768FD;
          margin: 10px;
        }

        div {
          font-size: 30px;
          text-align: center;
          padding: 16px;
        }

        border-right: 1px solid hsla(0, 0%, 88.2%, 0.8823529411764706);
      }

      .statistics-type {
        display: flex;
        flex-direction: column;

        p {
          width: 70px;
          height: 24px;
          border: 1px solid hsla(0, 0%, 88.2%, 0.8823529411764706);
          text-align: center;
          line-height: 24px;
          margin: 5px 4px 0 4px;
          cursor: pointer;
        }

        .active {
          color: #0768FD;
          border: 1px solid #0768FD;
        }
      }
    }
  }

  .parking-monitor {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .monitor-card {
      flex: 1;
      min-height: 100px;
      margin-right: 10px;
      border: 1px solid hsla(0, 0%, 88.2%, 0.8823529411764706);
      padding: 10px;

      &:last-child {
        margin-right: 0;
      }

      .info-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .info-card {
          flex: 1;

          &:first-child {
            margin-right: 10px;
          }
        }
      }

      .info-card {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        background: #EFF8FF;
        color: #0768FD;
        margin-top: 10px;
        font-size: 14px;
        padding: 14px;
        border: 1px solid #A5D9FF;

        .value {
          font-size: 20px;
          font-weight: bold;
          margin-left: 5px;
        }
      }

      .monitor-card__content {
        height: 300px;
        text-align: center;
        margin-top: 10px;

        .tip {
          font-size: 14px;
          color: #565c68;
          display: inline-block;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
